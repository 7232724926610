import React from 'react'
import ReactPlayer from 'react-player'

import demoVideo from '../../assets/videos/gamedemo.mp4'

const Demo = () => (
  <ReactPlayer
    url={demoVideo}
    controls
    height="500px"
    className="react-player"
    style={{ border: '1px solid #ddd' }}
  />
)

export default Demo
